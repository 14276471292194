.footer-container {
  position: relative;
}
.footer-container > hr {
  border: 2px solid var(--lightgray);
}

.footer {
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  display: flex;
  flex-direction: column;
  height: 20rem;
}
.social-links {
  gap: 4rem;
  display: flex;
}
.social-links > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.logo-f{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.logo-f>img{
    
    width: 10rem;
}

.footer-blur-1{
  width: 26rem;
  height: 12rem;
  bottom: 0;
  right: 15%;
  filter: blur(200px);
  background: red;

}
.footer-blur-2{
  bottom: 0;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: rgb(255, 115,0);
}
