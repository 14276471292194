.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 3rem;
  width: 10rem;
}

.header-menu {
  gap: 2rem;
  display: flex;
  list-style: none;
  color: white;
}

.header-menu > li:hover {
  cursor: pointer;
  color: var(--orange);
}
@media screen and (max-width: 768px) {

.header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
}

.header-menu{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;

}

}
